.dropdown-button{
    margin: 16px 12px !important;
}

.photobtn{
    width: 100px;
}

input[type="file"] {
    display: none;
}

.nextui-c-iWjDFM-ihglIOL-css{
    height: max-content !important;
    padding: 6px 12px !important 
}

.sale-itemName-input-dark{
    width: 97.5vw;
    max-width: 400px;
    font-weight: 600;
    font-size: 14px;
    border-width: 0px 0px 0.5px 0px;
    background-color: #0c0c0c;
    padding: 8px 0px 8px 4px;
}

.sale-itemName-input-light{
    width: 97.5vw;
    max-width: 400px;
    font-weight: 600;
    font-size: 14px;
    border-width: 0px 0px 0.5px 0px;
    background-color: #fff;
    padding: 8px 0px 8px 4px;
}

.sale-itemName-input-light::placeholder{
    font-size: 14px;
    font-weight: 600;
}

.sale-itemName-input-dark::placeholder{
    font-size: 14px;
    font-weight: 600;
}

.sale-itemDesc-input-dark{
    width: 97.5vw;
    max-width: 400px;
    min-width: 330px;
    max-height: 100px;
    min-height: 50px;
    font-weight: 600;
    font-size: 14px;
    border-width: 0px;
    background-color: #0c0c0c;
    padding: 4px 0px 4px 4px;
    margin: 4px 0px
}
.sale-itemDesc-input-light{
    width: 97.5vw;
    max-width: 400px;
    min-width: 330px;
    max-height: 100px;
    min-height: 50px;
    font-weight: 600;
    font-size: 14px;
    border-width: 0px;
    background-color: #fff;
    padding: 4px 0px 4px 4px;
    margin: 4px 0px
}

.sale-itemDesc-input-light::placeholder{
    font-size: 14px;
    font-weight: 600;
}

.sale-itemDesc-input-dark::placeholder{
    font-size: 14px;
    font-weight: 600;
}

.sale-buttons{
    padding: 6px 8px !important;
}

.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 12px;
}

.input-wrapper {
    position: relative;
    display: inline-block;
    margin-left: 4px;
}

.currency-icon {
    position: absolute;
    left: 8px; /* Adjust based on your padding */
    top: 50%;
    transform: translateY(-50%);
    color: #0072f5;
    font-weight: 600;
}

.sale-price-input {
    width: 70px;
    padding-left: 20px; /* Adjust so the text does not overlap the currency icon */
    height: 28px;
    border-radius: 12px;
    border: none;
    background-color: #CEE4FE;
    color: #0072f5;
    font-weight: 600;
    font-size: 14px;
    /* Remove the default webkit styles */
    -webkit-appearance: none;
}

.sale-price-input::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: #0072f5;
}

.gallery-thumbnail img {
    max-width: 400px;
  width: 97.5vw;
  cursor: pointer;
  border-radius: 8px;
  transition: transform 0.5s ease;
  margin-top: 8px;
  height: 97.5vw;
  max-height: 400px;
  object-fit: cover;
}

.gallery-thumbnail img:hover {
  transform: scale(1);
}

.gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.close-icon,
.prev-icon,
.next-icon {
  position: absolute;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.close-icon {
  top: 20px;
  right: 20px;
}

.prev-icon {
  left: 10px;
}

.next-icon {
  right: 10px;
}

.gallery-image {
  max-width: 80%;
  max-height: 80%;
  border-radius: 8px;
}

.homebg {
    position: relative;
    overflow: hidden; /* This ensures the pseudo-element is contained within the bounds of .homebg */
}

.homebg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/HomeImage.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 1; /* Apply opacity to the background image only */
    z-index: 1; /* Ensure it's below the content */
}

.content {
    position: relative;
    z-index: 2; /* This ensures the content is above the background */
    /* Add your content styles here */
}

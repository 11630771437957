.dropdown-button{
    margin: 16px 12px !important;
}

.photobtn{
    width: 100px;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 12px;
    background-color: #F1F3F5;
    color: 'gray'
}